<template>
    <div class="toexamine">
         <div class="toexamine_head">
            <div style="margin-right: 10px;">机器人:</div>
            <div style="margin-right: 10px;"><el-input v-model="input" placeholder="请输入内容"></el-input></div>
            <div style="margin-right: 10px;">起始时间:</div>
            <div style="margin-right: 10px;">
                    <el-date-picker
                    v-model="value1"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
            </div>
            <div>
                <el-button type="primary" icon="el-icon-search">查询</el-button>
            </div>
         </div>
         <div>
               <el-table
                 border
                 height="600px"
                 :header-cell-style="{background: 'rgb(240, 247, 253)'}"
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    type="index"
                    label="序号"
                    width="60"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="RobotId"
                    label="RobotID"
                    width="280">
                </el-table-column>
                <el-table-column
                    prop="RobotName"
                    label="机器人名称"
                    width="380">
                </el-table-column>
                <el-table-column
                    prop="Text"
                    label="日志详情"
                    width="580">
                </el-table-column>
                <el-table-column
                    prop="TypeName"
                    label="审核状态"
                    width="180">
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="280">
                    <template slot-scope="scope">
                        <div class="caozuo">
                            <div @click="Approved(scope.row.RobotId)" class="caozuo_1">
                                <div class="el-icon-circle-check"></div>
                                <div>通过</div>
                            </div>
                            <div class="caozuo_1" @click="refuse(scope.row.RobotId)">
                                <div class="el-icon-circle-close"></div>
                                <div>拒绝</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                </el-table>
         </div>
    </div>
</template>
<script>
import  RobotCenter  from "../../api/RobotCenter"
export default {
    data(){
        return{
            input:"",
            value1:[],
            tableData: [],
            timer:'',
            loading:false
        }
    },
    created(){
        this.AuditGetPageList()
    },
    methods:{
    //循环执行UploadProcessMonitor
    UploadProcessMonitor(obj){
        RobotCenter.UploadProcessMonitor(obj).then(res=>{
            console.log(res,'ressss')
            if(res.data.Data.finishedCount === res.data.Data.totalCount 
                && res.data.Data.finishedCount !== 0 
                && res.data.Data.totalCount !== 0){
                    clearInterval(this.timer);
                    return;
            }
        })
    },
    //拒绝
    refuse(RobotId){
       this.$confirm('是否拒绝通过此审核?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            let obj={
                RobotId:RobotId,
                Type:'02',
            }
             RobotCenter.AuditSubmitData(obj).then(res=>{
                 console.log(res);
                if(res.data.Status==1){
                    this.AuditGetPageList()
                    this.$message({
                       type: 'success',
                       message: '拒绝成功'
                   });
                }
             });
        }).catch(() => {       
        });
    },
    //同意
    Approved(RobotId){
       this.$confirm('是否通过此审核?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            let obj={
                RobotId:RobotId,
                Type:'01',
            };
            RobotCenter.AuditSubmitData(obj).then(res=>{
                console.log(res);
                if(res.data.Status==1){
                    this.AuditGetPageList();
                    this.$message({
                       type: 'success',
                       message: '审核通过'
                    });
                    Promise.all([RobotCenter.UploadProcessMonitor(obj),RobotCenter.UploadToRobotAi(obj)]).then(res=>{
                        if(res[0].data.Data.finishedCount === res[0].data.Data.totalCount 
                            && res[0].data.Data.finishedCount !== 0 
                            && res[0].data.Data.totalCount !== 0){
                                clearInterval(this.timer);
                        }else if(res[0].data.Data.finishedCount == 0 && res[0].data.Data.totalCount == 0){
                            clearInterval(this.timer);
                        }
                    });
                    this.timer = setInterval(this.UploadProcessMonitor,2000,obj);
                }
             });            
        }).catch(() => { 
            // this.loading = true;
            // setImmediate(()=>{
            //     this.loading = false;
            // },3000)     
        });
    },
        AuditGetPageList(){
            let obj={
                Key:"",
                page:1,
                rows:20,
                LogType:'01'
            }
            RobotCenter.AuditGetPageListData(obj).then(res=>{
                 if(res.data.Status==1){
                    this.tableData=res.data.Data.rows
                 }else{
                    alert(res.data.message)
                 }
                 console.log(res);
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.caozuo{
    display: flex;
    align-items: center;
    .caozuo_1{
        display: flex;
        align-items: center;
        color: #409eff;
        margin-right: 10px;
        cursor:pointer;
    }
}
.toexamine{
  width: 100%;
  padding: 20px 40px 40px 40px;
  box-sizing: border-box;
  height: 745px;
}
.toexamine_head{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
</style>